!function(e,t,n){function a(){if(document.location.hostname.indexOf('paydock.com') === -1) return; var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});
window.Beacon('init', '453b5e2a-e6dd-423a-8731-ec0061d5b049');
window.onload = function() {
    window.Beacon('config', {color: document.documentElement.style.getPropertyValue('--main_button_color')});
    window.Beacon('on', 'open', function() {
        document.body.classList.add('beacon-opened')
    });
    window.Beacon('on', 'close', function() {
        document.body.classList.remove('beacon-opened')
    });

}
